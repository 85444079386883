import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslatePipe } from '@spartacus/core';
import { getUrlOfEndpoint } from '@util/functions/strings';
import { Observable, of } from 'rxjs';
import { KurzFileDownloadService } from 'src/app/custom/services/kurz-file-download.service';

export interface LegalDocument {
  code?: string;
  description?: string;
  updatedOn?: string;
}

@Injectable({
  providedIn: 'root'
})
export class KurzLegalDocumentService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly translatePipe: TranslatePipe,
    private readonly kurzFileDownloadService: KurzFileDownloadService
  ) { }

  getDocumentInfo(documentType: string): Observable<LegalDocument> {
    const endpoint = '/legalDocument/documentMeta';
    const url = getUrlOfEndpoint(endpoint, { documentType });

    return this.httpClient.get<LegalDocument>(url);
  }

  downloadDocument(documentType: string) {
    const endpoint = '/legalDocument/download';
    return this.kurzFileDownloadService.downloadFile(endpoint, { documentType }, 'application/pdf', `${this.translatePipe.transform('legalPages.' + documentType)}.pdf`);
  }
}

export class KurzLegalDocumentTestingService {
  getDocumentInfo(documentType: string): Observable<LegalDocument> {
    return of({});
  }

  downloadDocument(): Observable<any> {
    return of({});
  }
}
